import { Button } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { modifyToken } from 'reducers/token'
import { Token } from 'services/cerbereTypes'

type Props= {
  token: Token;
}
// to do other errors
export default function ModifyButton({ token }: Props): ReactElement {
  const dispatch = useDispatch()

  const selectToken = () => {
    dispatch(modifyToken(token))
  }

  return (
    <Button
      className="button"
      onClick={selectToken}
      variant="outlined"
    >
      {terms.Token.modificationRequest}
    </Button>
  )
}
